const ImportCustomSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 545 307" fill="none" className="mx-auto w-full">
			<path
				fill="#96281B"
				d="M220.288 97.995s-8.784 1.156-10.864 7.86c-1.387 4.854 1.387 8.09 5.779 11.095 4.392 3.005-1.156 11.326-8.784 6.01.925 7.628 8.09 9.246 12.251 7.397-.231 2.542-8.784 5.085-5.085 11.095 2.311 4.161 7.628 1.387 9.246 4.161 3.236 5.547-5.317 8.09.231 13.869 0 0 46.461 6.01 58.481 2.311 12.483-3.698 12.714-6.703 10.865-12.713-1.619-6.01 9.246-.231 10.633-6.935-4.855.694-12.945-1.386-9.94-7.396 2.08-3.93 5.085-1.156 9.246-1.85 4.854-.693 8.09-9.246 1.387-12.482-5.316-2.543-5.316-7.397-3.236-10.633 2.543-3.467 3.467-10.633-6.241-11.326-9.709-.463-73.969-.463-73.969-.463Z"
			/>
			<path
				fill="#ED8A40"
				d="M275.534 148.618c-5.548-9.709-1.387-24.733-1.387-24.733l-17.105 3.467-17.105-3.467s2.08 7.165 1.386 15.024c6.01 7.166 19.88 20.111 34.211 9.709Z"
			/>
			<path
				fill="#ED8A40"
				d="M215.203 162.256c9.94 8.784 29.588 27.969 42.07 27.969s34.673-18.261 44.612-27.045c-4.854-1.849-19.648-7.628-23.115-10.633-1.156-1.155-2.312-2.542-3.005-3.929-14.331 10.17-28.201-2.774-34.211-9.94-.462 5.086-1.849 10.171-5.778 13.638-3.93 3.236-15.95 8.091-20.573 9.94Z"
			/>
			<path
				fill="#FFB680"
				d="M304.659 71.644c-1.618-3.005-4.161-3.467-5.779-3.005 0 0-4.623-14.794-7.859-25.658-10.864-5.085-21.035-9.477-33.286-10.402v-.23H250.8c-7.628.23-15.256.462-22.653 1.848-1.849.463-3.698.925-5.547.925-2.312 12.251-6.241 33.748-7.859 33.517h-.232c-.231 0-.231 0-.462-.231-1.618-.462-3.467 0-5.085 2.774-2.774 5.085 0 15.256 3.698 18.26 1.849 1.388 3.468 2.543 4.623 3.237 1.85 9.246 5.086 18.03 9.94 23.346 12.713 13.638 24.502 16.643 27.738 17.106h1.387c2.312-.232 15.025-2.543 28.663-17.337 4.854-5.085 8.321-8.784 10.402-22.422 1.387-.693 2.774-1.849 5.085-3.698 4.161-3.005 6.935-12.945 4.161-18.03Z"
			/>
			<path
				fill="#ED8A40"
				d="m214.048 75.111 1.156 9.94s-3.468-1.85-4.161-6.472c-.231-2.774.924-4.623 3.005-3.468ZM297.957 75.111l-1.156 9.94s3.467-1.85 4.161-6.472c.231-2.774-.694-4.623-3.005-3.468Z"
			/>
			<path
				fill="#DF3F18"
				d="M261.433 113.714c-1.618-.231-3.467-.231-4.392 1.156-1.156-1.387-2.774-1.387-4.392-1.156-1.618.231-4.854.231-7.859-1.156.231 1.156 2.312 3.467 5.085 5.317 2.543 1.618 4.392 2.542 6.935 2.542 2.543 0 4.623-.693 6.935-2.542 2.773-2.081 4.854-4.161 5.085-5.317-2.774 1.387-6.01 1.387-7.397 1.156Z"
			/>
			<path
				fill="#ED8A40"
				d="M257.041 103.774c-2.08 0-4.161-1.156-4.392 0-.231.694 2.543 1.849 4.161 1.849 1.849 0 4.623-.924 4.161-1.849 0-.924-1.849 0-3.93 0Z"
			/>
			<path
				fill="#96281B"
				d="M211.042 68.408s8.09 4.854 8.784-2.774c.462-5.547 8.552-12.251 8.552-12.251s5.779-5.085 21.035-.693 22.422-14.332 22.422-14.332.694 16.643 9.477 17.568c8.784.924 11.789 3.698 13.176 9.246 1.387 5.316 6.01 7.166 8.784 4.16 6.703-7.396 14.794-49.466-19.648-53.858-13.869-22.653-44.613-17.567-64.954 3.698-14.794 15.488-13.638 36.06-7.628 49.236Z"
			/>
			<path
				fill="#ED8A40"
				d="M266.287 69.795s0 9.477 1.618 10.633c1.618.925 6.01-5.316 6.01-5.316s2.081-10.865 10.864-11.096c-4.392-1.387-13.407-2.311-18.492 5.779ZM247.795 69.795s0 9.477-1.618 10.633c-1.618.925-6.01-5.316-6.01-5.316s-2.08-10.865-10.864-11.096c4.392-1.387 13.407-2.311 18.492 5.779Z"
			/>
			<path
				fill="#fff"
				d="M268.136 78.81s-1.387 1.156-.231 2.543c1.156 1.387 7.859 4.623 14.332.924 3.698-2.08 3.929-3.93 3.929-3.93s-9.939-7.858-18.03.463Z"
			/>
			<path
				fill="#4385F5"
				d="M279.694 79.041c0 2.08-1.618 3.93-3.929 3.93-2.081 0-3.93-1.618-3.93-3.93 0-2.08 1.618-3.93 3.93-3.93 2.311 0 3.929 1.619 3.929 3.93Z"
			/>
			<path
				fill="#6B2618"
				d="M286.166 77.192c-.462-.231-.693-.694-1.155-.925 1.387-.462 2.542-1.155 3.236-2.542 0 0-1.849 1.618-5.086 1.618-5.547-2.08-12.019-1.618-15.718 3.698-.693.925-.462 1.387.231.462 4.392-4.623 12.482-5.085 17.568-.924 0 0-.462 2.08-2.312 3.005-1.387.924-.693.924.231.462 1.387-.693 2.543-1.85 3.699-3.467 1.618-.231 3.236-1.618 3.929-2.774-1.849 1.156-2.311 1.387-4.623 1.387Z"
			/>
			<path
				fill="#6B2618"
				d="M277.845 79.041c0 1.156-.925 1.85-1.849 1.85-.925 0-1.85-.925-1.85-1.85 0-1.156.925-1.85 1.85-1.85.924 0 1.849.694 1.849 1.85Z"
			/>
			<path
				fill="#fff"
				d="M278.308 77.654c0 .694-.463 1.156-1.156 1.156-.694 0-1.156-.462-1.156-1.156 0-.693.462-1.155 1.156-1.155.693 0 1.156.462 1.156 1.155ZM246.177 78.81s1.387 1.156.231 2.543c-1.155 1.387-7.859 4.623-14.331.924-3.698-2.08-3.93-3.93-3.93-3.93s9.94-7.858 18.03.463Z"
			/>
			<path fill="#4385F5" d="M238.55 82.97a3.93 3.93 0 1 0 0-7.858 3.93 3.93 0 0 0 0 7.859Z" />
			<path
				fill="#6B2618"
				d="M228.147 77.192c.463-.231.694-.694 1.156-.925-1.387-.462-2.542-1.155-3.236-2.542 0 0 1.849 1.618 5.085 1.618 5.548-2.08 12.02-1.618 15.719 3.698.693.925.462 1.387-.231.462-4.392-4.623-12.483-5.085-17.568-.924 0 0 .925 1.849 2.543 3.005 1.156.693.462.924-.694.462-1.155-.693-2.542-1.618-3.467-3.467-1.618-.231-3.236-1.618-3.93-2.774 1.85 1.156 2.312 1.387 4.623 1.387Z"
			/>
			<path
				fill="#6B2618"
				d="M236.7 79.041c0 1.156.925 1.85 1.849 1.85.925 0 1.85-.925 1.85-1.85 0-.924-.925-1.85-1.85-1.85-.924 0-1.849.925-1.849 1.85Z"
			/>
			<path
				fill="#fff"
				d="M238.549 77.654c0 .694.462 1.156 1.156 1.156.693 0 1.155-.462 1.155-1.156 0-.693-.462-1.155-1.155-1.155-.694.23-1.156.693-1.156 1.155Z"
			/>
			<path
				fill="#6B2618"
				d="M236.238 61.705c4.392.23 6.935 1.618 9.709 4.623.693.924 1.387 1.849 1.849 2.311.924 1.85-.462 2.543-2.312.231-1.849-2.08-2.542-4.16-6.472-5.316-3.005-.925-7.859 0-10.633 1.849-1.387.925-1.618.231-.462-.693 3.005-2.543 5.316-3.005 8.321-3.005ZM278.077 61.705c-4.392.23-6.935 1.618-9.709 4.623-.693.924-1.387 1.849-1.849 2.311-.925 1.85.462 2.543 2.311.231 1.85-2.08 2.543-4.16 6.473-5.316 3.005-.925 7.859 0 10.633 1.849 1.387.925 1.618.231.462-.693-3.005-2.543-5.316-3.005-8.321-3.005ZM284.779 74.187c-3.929-3.236-10.401-4.16-15.487.462-.693.694-.462.925 0 .463 2.774-2.312 6.241-2.774 9.94-2.543 2.311.23 3.467.924 5.547 1.849.463.462.463.231 0-.231ZM229.766 74.187c3.929-3.236 10.402-4.16 15.487.462.693.694.462.925 0 .463-2.774-2.312-6.241-2.774-9.94-2.543-2.311.23-3.467.924-5.547 1.849-.694.462-.463.231 0-.231Z"
			/>
			<path
				fill="#A4C9FF"
				d="M339.563 214.958c.924-24.964-6.704-39.758-18.261-45.999-11.558-6.241-38.834-14.1-39.759-14.331l-24.964 19.648-23.578-19.648c-.924.231-28.894 8.09-40.22 14.331-11.558 6.241-19.186 21.035-18.261 45.999.693 20.111-.694 51.778-.462 74.894h165.967c.231-23.116-1.156-54.783-.462-74.894Z"
			/>
			<path
				fill="#77A7FB"
				d="m270.448 162.949-6.472-2.774-7.397 13.638-7.397-13.638-6.703 2.774 9.708 15.95v110.953h9.015V178.205l9.246-15.256Z"
			/>
			<path
				fill="#fff"
				d="M241.323 138.909c-.231 6.704 9.246 24.04 9.246 24.04l-16.412 15.95-5.316-23.115s5.316-14.101 12.482-16.875ZM272.759 138.909c.231 6.704-10.402 24.271-10.402 24.271l17.337 15.719 5.316-23.347s-5.085-13.869-12.251-16.643ZM259.353 182.828a2.566 2.566 0 0 1-2.543 2.543c-1.387 0-2.542-1.156-2.542-2.543a2.564 2.564 0 0 1 2.542-2.542c1.387 0 2.543 1.155 2.543 2.542ZM259.353 206.175c0 1.387-1.156 2.542-2.543 2.542a2.564 2.564 0 0 1-2.542-2.542c0-1.387 1.155-2.543 2.542-2.543a2.566 2.566 0 0 1 2.543 2.543ZM259.353 229.521a2.566 2.566 0 0 1-2.543 2.543c-1.387 0-2.542-1.156-2.542-2.543 0-1.387 1.155-2.543 2.542-2.543a2.566 2.566 0 0 1 2.543 2.543ZM259.353 255.179a2.566 2.566 0 0 1-2.543 2.543c-1.387 0-2.542-1.156-2.542-2.543 0-1.387 1.155-2.543 2.542-2.543 1.387-.231 2.543.925 2.543 2.543Z"
			/>
			<path
				fill="#A4C9FF"
				d="M178.449 175.663s-24.502 65.878-26.351 76.742c-1.618 10.864 12.251 36.06 18.723 36.984 9.478 1.387 17.106-1.386 26.583-17.567 9.246-16.181 7.628-85.295 7.628-85.295l-26.583-10.864Z"
			/>
			<path
				fill="#FFB680"
				d="M207.806 105.161c-3.468 1.849-8.091 3.467-11.789 6.935-1.618 1.618-11.095 12.02-12.251 18.492-1.156 7.397 5.316 16.181 5.779 24.04.693 10.633-3.468 15.025-3.468 15.025l18.493 6.472s7.165-14.332 8.321-19.186c1.156-5.085 1.849-11.557.925-16.18-1.387-6.935 2.774-16.875 5.547-20.342 2.543-3.467-.462-6.703-6.241-2.774-.924.694-3.698 3.236-3.698 3.236l-2.543-8.09.925-7.628Z"
			/>
			<path
				fill="#77A7FB"
				d="M205.263 180.286s-1.618 79.516-5.085 89.456c-5.548 15.949-19.648 20.341-19.648 20.341h15.718s12.251-8.09 13.407-32.361c.693-14.563-1.849-61.025-3.236-76.512-.462-4.16-1.156-.924-1.156-.924Z"
			/>
			<path fill="#438EE8" d="m210.754 172.558-26.576-10.737-5.542 13.717 26.577 10.737 5.541-13.717Z" />
			<path
				fill="#77A7FB"
				d="M311.13 191.381s-9.477 45.075-10.17 63.567c-.694 18.492 0 34.441 0 34.441l6.703.463c0 .231 1.618-80.672 3.467-98.471Z"
			/>
			<path
				fill="#fff"
				d="M230.689 129.201c-12.713 0-16.874-6.241-19.417-15.718-1.155-5.086-1.618-18.724-1.618-19.186h3.699c0 .231.462 13.869 1.618 18.492 2.311 9.015 5.779 13.638 18.261 12.713l.231 3.699h-2.774ZM256.809 3.454c-10.17-.924-33.517-.23-44.15 20.11 0 0 2.774 4.855 6.01 1.619 11.558-10.402 27.738-10.633 37.678-10.633 10.171 0 27.045 0 38.371 10.633 3.467 3.005 6.01-1.619 6.01-1.619-10.402-20.34-33.748-21.034-43.919-20.11Z"
			/>
			<path
				fill="#494949"
				d="M311.593 73.03s-.463-14.1-.694-18.029c-.462-7.628-1.618-20.11-4.161-26.814-8.321-22.653-35.366-27.044-49.466-27.044-14.332 0-41.839 4.391-50.16 27.044-2.312 6.473-3.467 18.03-4.161 25.89-.231 4.623-.462 18.954-.462 18.954h3.929s0-14.332.463-18.955c.462-6.01 1.618-14.793 4.623-23.115 6.934-19.648 32.592-21.728 45.074-21.728 12.714 0 38.834 2.08 45.769 21.728 3.236 9.246 4.391 18.724 4.854 24.733.231 3.93.462 17.568.462 17.568h3.93v-.231Z"
			/>
			<path fill="#BABABA" d="M207.342 70.488h-5.085v21.729h5.085V70.488Z" />
			<path
				fill="#383838"
				d="M216.357 96.146h-5.778c-2.774 0-5.086-2.311-5.086-5.085V71.18c0-2.773 2.312-5.085 5.086-5.085h5.778c.694 0 1.156.463 1.156 1.156V94.76c0 .925-.462 1.387-1.156 1.387ZM200.408 94.066h-.231a2.072 2.072 0 0 1-2.081-2.08V70.256c0-1.156.925-2.08 2.081-2.08h.231c1.155 0 2.08.924 2.08 2.08v21.728c-.231 1.156-1.156 2.08-2.08 2.08Z"
			/>
			<path fill="#BABABA" d="M306.004 92.446h5.086V70.718h-5.086v21.728Z" />
			<path
				fill="#383838"
				d="M297.261 96.609h5.778c2.774 0 5.086-2.312 5.086-5.086V71.644c0-2.774-2.312-5.085-5.086-5.085h-5.778c-.694 0-1.156.462-1.156 1.156v27.507c-.231.924.462 1.387 1.156 1.387ZM313.21 94.528h.231a2.072 2.072 0 0 0 2.081-2.08V70.72c0-1.156-.925-2.08-2.081-2.08h-.231c-1.156 0-2.08.924-2.08 2.08v21.728c0 1.156.924 2.08 2.08 2.08ZM244.327 126.658c0 2.312-2.773 4.392-6.472 4.392-3.467 0-6.472-1.849-6.472-4.392 0-2.542 2.774-4.392 6.472-4.392 3.467 0 6.472 2.081 6.472 4.392Z"
			/>
			<path
				fill="#FFB680"
				d="M196.017 112.096s14.563-9.709 18.03-11.327c3.698-1.849 6.01 1.387.693 6.01-5.316 4.623-14.562 10.633-14.562 10.633l-4.161-5.316Z"
			/>
			<path
				fill="#FFB680"
				d="M197.635 115.101s16.18-6.473 19.879-7.628c3.929-1.156 5.779 2.542-.462 6.01-6.242 3.467-16.412 7.628-16.412 7.628l-3.005-6.01Z"
			/>
			<path
				fill="#ED8A40"
				d="M212.66 139.603c.462-5.779 0-6.935-.462-9.708-.463-3.237.231-7.397-1.156-10.402-1.618-3.236-4.161-5.086-8.553-4.161-6.01 1.387-8.321 4.392-8.321 4.392s4.623-1.849 6.934-2.543c3.468-.924 6.935-.693 8.322 3.468.693 2.542 1.155 6.009 1.618 8.552.231 1.849 1.618 9.94.462 10.171-1.156 0-3.698-.694-4.854-4.854-.231.924-.462 7.165-1.156 7.165-4.161-.462-4.623-3.698-4.854-7.165 0-1.618-.231-3.93-.694-6.935-1.618 2.543-5.547 4.854-7.165 6.01 1.849 1.618 5.316 5.548 6.703 7.166 2.543 3.005 4.854 7.628 5.317 12.482 6.241-6.01 2.773-12.251 2.542-14.331 1.85 1.849 5.086 3.236 5.317.693Z"
			/>
			<path
				fill="#4D4D4D"
				d="M475.018 139.834c0-3.467-2.774-6.01-6.472-6.01H297.724c-3.467 0-6.472 2.774-6.472 6.01v106.33h183.535v-106.33h.231Z"
			/>
			<path
				fill="#E0E0E0"
				d="M297.724 266.043h170.822c3.467 0 6.472-2.774 6.472-6.01v-13.869H291.252v13.869c0 3.467 3.005 6.01 6.472 6.01Z"
			/>
			<path fill="gray" d="M409.833 263.731h-45.769v21.729h45.769v-21.729Z" />
			<path fill="#777" d="M415.38 263.731h-45.768v21.729h45.768v-21.729Z" />
			<path fill="#E0E0E0" d="M407.984 283.379h-56.632v6.241h56.632v-6.241Z" />
			<path fill="#A5A5A5" d="M426.245 283.379h-56.633v6.241h56.633v-6.241Z" />
			<path
				fill="#3F3F3F"
				d="M484.264 139.834c0-3.467-2.774-6.01-6.472-6.01H306.97c-3.467 0-6.472 2.774-6.472 6.01v106.33h183.535v-106.33h.231Z"
			/>
			<path
				fill="#A5A5A5"
				d="M307.202 266.043h170.821c3.468 0 6.473-2.774 6.473-6.01v-13.869H300.961v13.869c-.231 3.467 2.542 6.01 6.241 6.01Z"
			/>
			<path
				fill="#E0E0E0"
				d="M409.139 180.286a16.572 16.572 0 0 1-16.643 16.642 16.572 16.572 0 0 1-16.642-16.642 16.572 16.572 0 0 1 16.642-16.643 16.573 16.573 0 0 1 16.643 16.643ZM254.036 283.379h-39.527v6.01h39.527v-6.01Z"
			/>
			<path fill="#A5A5A5" d="M319.684 283.379H236.7v6.01h82.984v-6.01Z" />
			<path fill="#FCC63F" d="M544.826 289.389H0v17.337h544.826v-17.337Z" />
		</svg>
	);
};

export default ImportCustomSvg;
