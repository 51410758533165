type HeadingParagraphImageLayoutProps = {
	heading: string;
	paragraph: string;
	className?: string;
	svg: React.ReactNode;
};

const HeadingParagraphImageLayout = ({ heading, paragraph, className = '', svg }: HeadingParagraphImageLayoutProps) => {
	return (
		<div className="flex flex-col gap-4 py-10 lg:flex-row lg:items-center lg:justify-between">
			<div className={`basis-1/2  ${className}`}>
				<h3 className="pb-2 text-3xl font-semibold ">{heading}</h3>
				<p className="whitespace-pre-line text-sm leading-6 text-slate-600">{paragraph}</p>
			</div>
			<div className="basis-1/2">{svg}</div>
		</div>
	);
};

export default HeadingParagraphImageLayout;
