import { useState } from 'react';

const initialState = {
	firstName: '',
	lastName: '',
	email: '',
	inquiry: '',
};

const ContactPage = () => {
	const [input, SetInput] = useState(initialState);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const post = fetch('https://email-system-flax.vercel.app/send-email', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(input),
		});
		console.log(post);

		SetInput(initialState);
	};

	return (
		<div className="bg-stone-400 py-10">
			<div className="container">
				<form className="mx-auto max-w-xl" onSubmit={handleSubmit}>
					<div className="  grid grid-cols-2 gap-4">
						<div className="col-span-full sm:col-span-1">
							<label htmlFor="first-name" className="text-md block font-medium leading-6 text-gray-900">
								First name
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="first-name"
									id="first-name"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									required
									onChange={(event) => SetInput((input) => ({ ...input, firstName: event.target.value }))}
									value={input.firstName}
								/>
							</div>
						</div>
						<div className="col-span-full sm:col-span-1">
							<label htmlFor="first-name" className="text-md block font-medium leading-6 text-gray-900">
								Last name
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="last-name"
									id="last-name"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									required
									onChange={(event) => SetInput((input) => ({ ...input, lastName: event.target.value }))}
									value={input.lastName}
								/>
							</div>
						</div>
						<div className="col-span-2">
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									required
									onChange={(event) => SetInput((input) => ({ ...input, email: event.target.value }))}
									value={input.email}
								/>
							</div>
						</div>

						<div className="col-span-2">
							<label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
								Inquiry/Complaints
							</label>
							<div className="mt-2">
								<textarea
									id="Inquiry"
									name="Inquiry"
									rows={3}
									className="block h-32 w-full resize-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									required
									onChange={(event) => SetInput((input) => ({ ...input, inquiry: event.target.value }))}
									value={input.inquiry}
								/>
							</div>
						</div>
					</div>
					<div className=" mt-4 text-right">
						<button
							className=" rounded-lg bg-indigo-600 px-5 py-3 text-white
						duration-150 hover:bg-indigo-700
						active:shadow-lg"
							type="submit"
						>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ContactPage;
