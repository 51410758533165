const GoodSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 739 466">
			<path
				fill="#00AEEF"
				d="M728.9 194.5c-2.7-41.7-28.1-81.4-65.3-111.7-21.8-17.7-47.7-33.1-75.8-44.9-29.2-12.3-60.9-26-95.5-27.5-8.5-.4-17.3.1-25.6 2-31.9 7.4 32.9 29.8 41.3 33.1C417.8 20.9 324.6-4.1 227.4.6c-18.7.9-38.1 3.1-54.3 10.7-16.1 7.5-28.2 21.8-25.1 35.6 2.5 11.4 14.2 24 4.2 33.1-2.9 2.6-7 4.2-11.1 5.6-21 7.2-43.8 11.2-63.5 20.2-30.6 13.9-50.8 38.8-59.5 64.7-8.7 25.9-7 52.8-2 78.9 9.5 49 31.5 97.3 73.4 134.7 59.8 53.4 155 79.1 249.3 81.7 114.1 3.1 236.3-28.6 308.6-97.4 10.7-10.2 20.5-21.5 24-34.1 3.5-12.6-.4-26.8-13.2-34.7-3.5-2.1-7.9-4.4-7.7-7.8.3-4.7 8.2-6.4 14.3-7.5 39.8-7.3 61.2-41.6 64-72.2.4-5.9.4-11.8.1-17.6Z"
			/>
			<path
				fill="#fff"
				d="M728.9 194.5c-2.7-41.7-28.1-81.4-65.3-111.7-21.8-17.7-47.7-33.1-75.8-44.9-29.2-12.3-60.9-26-95.5-27.5-8.5-.4-17.3.1-25.6 2-31.9 7.4 32.9 29.8 41.3 33.1C417.8 20.9 324.6-4.1 227.4.6c-18.7.9-38.1 3.1-54.3 10.7-16.1 7.5-28.2 21.8-25.1 35.6 2.5 11.4 14.2 24 4.2 33.1-2.9 2.6-7 4.2-11.1 5.6-21 7.2-43.8 11.2-63.5 20.2-30.6 13.9-50.8 38.8-59.5 64.7-8.7 25.9-7 52.8-2 78.9 9.5 49 31.5 97.3 73.4 134.7 59.8 53.4 155 79.1 249.3 81.7 114.1 3.1 236.3-28.6 308.6-97.4 10.7-10.2 20.5-21.5 24-34.1 3.5-12.6-.4-26.8-13.2-34.7-3.5-2.1-7.9-4.4-7.7-7.8.3-4.7 8.2-6.4 14.3-7.5 39.8-7.3 61.2-41.6 64-72.2.4-5.9.4-11.8.1-17.6Z"
				opacity=".7"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M569.7 92.1V31.6M485.601 58.3v374.6M485.601 27.8v19.8M569.7 388.2V98.9M685.401 301.6V197.3h-66.3v190.9h66.3v-78.9"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M628.7 245.6V204h46.7v87.7h-46.7v-39.1M635.7 297.1h-12.5v4.2h12.5v-4.2ZM650.401 421.3h-145.8v11h145.8v-11ZM669.401 410.3h-145.8v11h145.8v-11ZM688.5 399.3H542.7v11h145.8v-11ZM707.5 388.3H561.7v11h145.8v-11Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="m720.9 432.4-.2-44.1h-13.2v11.1l-19-.1v11.1l-19-.1v11.1l-19.1-.1.3 11.1h70.2Z"
			/>
			<path
				fill="#263238"
				d="M722 335.5h-20.2l-.4.3-57.4 39.6v56.8c0 .1 0 .3.1.4h2.9c0-.1.1-.3.1-.4V377l27.3-18.8v51.5c0 .3.1.5.2.7h2.6c.1-.2.2-.5.2-.7v-53.5l23.5-16.2v47.7c0 .3.1.6.2.8h2.5c.2-.2.2-.5.2-.8v-49.1H722c.8 0 1.5-.7 1.5-1.5 0-.9-.6-1.6-1.5-1.6ZM509.8 432.7c0-.1.1-.3.1-.4V377l27.3-18.8v51.5c0 .3.1.5.2.7h2.6c.1-.2.2-.5.2-.7v-53.5l23.5-16.2v47.7c0 .3.1.6.2.8h2.5c.2-.2.2-.5.2-.8v-49.1H585c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-20.2l-.4.3-57.5 39.6v56.8c0 .1 0 .3.1.4h2.8Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M32.2 258.6v105.6h216.4V155.1M248.6 140.5V64.1H32.2v180M692.1 187h-80.5c-1.5 0-2.7-1.2-2.7-2.7v-1.6c0-1.5 1.2-2.7 2.7-2.7h80.5c1.5 0 2.7 1.2 2.7 2.7v1.6c0 1.5-1.2 2.7-2.7 2.7Z"
			/>
			<path stroke="#263238" strokeMiterlimit="10" d="M17.5 364.2H301v67M42.4 364.4V432" />
			<path
				stroke="#263238"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="6"
				d="M68.4 282.4s7.3-.3 11 3.3"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M141.4 415.4c0-3 .4-5.8 1-8.2v-9.3h7.9c4.9 0 8.9 7.9 8.9 17.5 0 9.7-4 17.5-8.9 17.5h-7.9v-9.3c-.6-2.5-1-5.3-1-8.2Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M142.5 432.9c4.915 0 8.9-7.835 8.9-17.5s-3.985-17.5-8.9-17.5c-4.915 0-8.9 7.835-8.9 17.5s3.985 17.5 8.9 17.5Z"
			/>
			<path
				fill="#828282"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M141.8 427.6c3.093 0 5.6-5.417 5.6-12.1 0-6.682-2.507-12.1-5.6-12.1-3.093 0-5.6 5.418-5.6 12.1 0 6.683 2.507 12.1 5.6 12.1Z"
			/>
			<path
				stroke="#263238"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="4"
				d="M79.4 285.7c1.2 1.2 2 2.9 2 5.2v126.2c0 3.3 2.7 6 6 6h80"
			/>
			<path
				stroke="#263238"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="6"
				d="M130.2 282.4s7.3-.3 11 3.3"
			/>
			<path
				stroke="#263238"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="4"
				d="M141.2 285.7c1.2 1.2 2 2.9 2 5.2v131.7M82.1 315.8h61.6M113.8 315.6v106.7M72.4 407.2l9.1-17.9M81.3 415.4h-8.5"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M57.8 415.4c0-3 .4-5.8 1-8.2v-9.3h7.9c4.9 0 8.9 7.9 8.9 17.5 0 9.7-4 17.5-8.9 17.5h-7.9v-9.3c-.6-2.5-1-5.3-1-8.2Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M58.8 432.9c4.915 0 8.9-7.835 8.9-17.5s-3.985-17.5-8.9-17.5c-4.915 0-8.9 7.835-8.9 17.5s3.985 17.5 8.9 17.5Z"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M52.5 415.5c0 6.7 2.5 12.1 5.6 12.1 3.1 0 5.6-5.4 5.6-12.1 0-6.7-2.5-12.1-5.6-12.1-3.1 0-5.6 5.4-5.6 12.1Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="m460.3 403.5 60.7 2.2 83.5-.8 32.1-1.6s6.6-17.6 4-27.1c-2.6-9.5-5.8-13.5-13.1-13.5s-19.7 5.9-20.2 10.2c-.5 4.3-4.1 9.2-4.1 9.2h-86.6s-3.3-18.7-14.9-25.3c-11.6-6.5-26.9-6.2-34.9 0-8 6.3-6.5 46.7-6.5 46.7Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M622.6 403c0-5.1-.6-9.8-1.8-14v-16h-13.5c-8.4 0-15.3 13.4-15.3 29.9s6.8 29.9 15.3 29.9h13.5V417c1.2-4.2 1.8-9 1.8-14Z"
			/>
			<path
				fill="#616161"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M620.9 432.9c8.45 0 15.3-13.387 15.3-29.9 0-16.513-6.85-29.9-15.3-29.9-8.45 0-15.3 13.387-15.3 29.9 0 16.513 6.85 29.9 15.3 29.9Z"
			/>
			<path
				fill="#E0E0E0"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M622.1 423.7c5.246 0 9.5-9.223 9.5-20.6s-4.254-20.6-9.5-20.6c-5.247 0-9.5 9.223-9.5 20.6s4.253 20.6 9.5 20.6Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M501.7 373h-13.5c-8.4 0-15.3 13.4-15.3 29.9s6.8 29.9 15.3 29.9h13.5c8.4 0 15.3-13.4 15.3-29.9s-6.9-29.9-15.3-29.9ZM348.7 373H334c-9.2 0-16.6 13.4-16.6 29.9s7.4 29.9 16.6 29.9h14.7c9.2 0 16.6-13.4 16.6-29.9s-7.4-29.9-16.6-29.9Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M491.4 398.1c0-5.9-.7-11.4-2.1-16.3v-18.5h-15.7c-9.8 0-17.8 15.6-17.8 34.8 0 19.2 8 34.8 17.8 34.8h15.7v-18.5c1.4-4.9 2.1-10.4 2.1-16.3Z"
			/>
			<path
				fill="#616161"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M489.4 432.9c9.83 0 17.8-15.58 17.8-34.8 0-19.219-7.97-34.8-17.8-34.8-9.831 0-17.8 15.581-17.8 34.8 0 19.22 7.969 34.8 17.8 34.8Z"
			/>
			<path
				fill="#E0E0E0"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M490.8 422.3c6.131 0 11.1-10.745 11.1-24s-4.969-24-11.1-24c-6.13 0-11.1 10.745-11.1 24s4.97 24 11.1 24Z"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M638.7 352c-2.8-8.8-62.6-132.3-67.2-137.3-4.6-5-90.2-31.5-90.2-31.5s-39.3-11.7-52-12.4c-12.7-.7-122.1 8.5-124.2 9.2-2.1.7-16.3 44.6-20.2 63.7-3.9 19.1-5.7 58-5.7 96.2s5.3 66.2 11.3 66.2c6 0 163.1 4.6 165.6 4.6s6-2.8 12.4-11.3 11.7-34 18.8-37.2c7.1-3.2 14.9-1.4 18.8 7.4 3.9 8.8 2.8 36.4 2.8 36.4s57.6-.3 95.7-1.3c1.4-3.8 5.2-14 10-25.7 6.1-14.8 16.3-13.3 20.8-9.5 4.1 3.4 3.6 21.9 1.2 33.6.6-.1.9-.2 1-.3 1.8-2.5 3.5-9.2 5-18 1.4-8.7-1.1-23.9-3.9-32.8Z"
			/>
			<path
				fill="#E0E0E0"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M508.8 406.2s57.6-.3 95.7-1.3c1.4-3.8 5.2-14 10-25.7 6.1-14.8 16.3-13.3 20.8-9.5 4.1 3.4 3.6 21.9 1.2 33.6.6-.1.9-.2 1-.3 1.8-2.5 3.5-9.2 5-18 1.4-8.8-1.1-24.1-3.9-32.9-2.8-8.8-62.6-132.3-67.2-137.3-4.6-5-90.2-31.5-90.2-31.5s-39.3-11.7-52-12.4h-.2l.5 2.2s10.6 20.4 8.6 237.2c10.7.3 17.6.5 18.1.5 2.5 0 6-2.8 12.4-11.3s11.7-34 18.8-37.2c7.1-3.2 14.9-1.4 18.8 7.4 3.7 8.9 2.6 36.5 2.6 36.5Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M637.5 373.8c1.7 7 1 20.3-.8 29.5.6-.1.9-.2 1-.3 1.8-2.5 3.5-9.2 5-18 .9-5.8.2-14.4-1.3-22.1-.3 3.8-1.2 8.2-3.9 10.9ZM486.2 362.9H279.9c1.6 26 5.9 43.3 10.6 43.3 6 0 163.1 4.6 165.6 4.6s6-2.8 12.4-11.3c6.1-8.1 11.2-31.6 17.7-36.6Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M351.6 177.7v.5c0 1.7 1.5 3 3.2 2.9l20.2-1.7c1.5-.1 2.7-1.4 2.7-2.9v-.5c0-1.7-1.5-3-3.2-2.9l-20.2 1.7c-1.5.1-2.7 1.3-2.7 2.9Z"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M429.5 173.1s4.6 96.9 4.6 110c0 13.1 1.4 110.5 1.4 110.5l51.8.5s4.4-30.6 4.4-62.5-4.9-108.3-7.6-130.7c-2.7-22.4-6.3-39.3-6.3-39.3l-48.3 11.5Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="m439.3 303.5 38.2-1.6s1.9 29.2 0 49.9-2.7 25.9-3.8 27c-1.1 1.1-34.4 0-34.4 0s-2.2-2.2-2.2-5.7-.8-64.7-.8-66.6c0-1.9 1.1-2.2 3-3Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="m434.4 190 1.1 12.3 37.9-5.7s-.3-3.3-2.7-8.5c-2.5-5.2-5.7-6.8-5.7-6.8l-27.8 6.3"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M469.8 166.8s6.3 16.6 11.2 61.7c4.9 45 6.8 136.7 3 155.2 0 0 0 3-3 5.2s-42 1.4-42 1.4"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M471.5 207.8s1.6-4.9 5.2 30.8c3.5 35.7 3.8 49.1 2.2 50.2-1.6 1.1-36.6 3-36.6 3M460.8 207.5s-21.8 2.5-23.5 4.4c-1.6 1.9 1.9 17.5 2.7 42.8.8 25.4.8 31.4.8 31.4"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="m470.9 214.9-27.3 4.4s-2.7.8-2.2 3.8 4.1 41.7 3.3 61.7M268 252.8v96.6"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="m272.2 241.8.2-12.4s-8.7-44.4-9.3-45.2c-.6-.8-71.4-1.4-72.2-1-.8.4-.2 4.7-.2 4.7s.8 14.2 1.4 59.9c.6 45.6-.2 138.9-.2 138.9l79 .6s-.8-6.2.4-26.6c1.2-20.4 1.4-114.1 1.4-115V243l-.5-1.2Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M194.6 186.4s60.5.9 62.1 1.3c1.6.4 2.7 3.6 3.3 5.4.5 1.8 8.2 35 8.7 40M268 362.3s.4 12-.9 14.5-2.7 3.3-4.7 3.6c-2 .4-68.5-.7-68.5-.7"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="m199.3 306.6-.9 66.3 64.8.9s1.5-65.7 1.3-66.6c-.2-.9-.2-1.8-3.8-2.2-3.6-.4-57.7-.9-58.7-.9-.9-.1-2.3 1-2.7 2.5Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M204.8 199.6s-6 8.4-6.5 10.4 0 3.5 1.5 3.6c1.5.2 56.3 0 56.3 0s2-.4 1.8-3.5M208.4 199.1h45s1.5 0 2.4 3.8M212.4 226.1s-4.4 1.5-4.5 5.4c-.2 4 .9 58.1.9 58.1M219.3 225.9s34.1.9 36.3 1.3c2.2.4 4.2 2.7 4.7 4.5.5 1.8.5 55.2.5 55.2"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M214.6 221.9s-7.3-.2-10.5 1.8c-3.3 2-2.7 7.3-2.5 9.3.2 2 .7 55.7.7 55.7s0 2.2 2.9 2.4c2.9.2 51.4.2 51.4.2s2 0 3.1-1.6M221.7 221.2s33.6.9 36.7 2.4c3.1 1.5 2.5 4.2 2.7 5.4M286.2 242.4h-15.9v5.6h15.9v-5.6Z"
			/>
			<path stroke="#263238" strokeMiterlimit="10" d="M280.5 240.8h-5.4v9.3h5.4v-9.3ZM284.8 353.2h-15.9v5.6h15.9v-5.6Z" />
			<path stroke="#263238" strokeMiterlimit="10" d="M279 351.6h-5.4v9.3h5.4v-9.3Z" />
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M429.4 180.9 309 190s-10.4 52.4-10.4 53.3c0 .9-3.4 85-2.1 105.5 1.2 20.4 1.5 37.5 1.5 37.5l136.5 4-5.1-209.4Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M429.4 180.9 309 190s-10.4 52.4-10.4 53.3c0 .9-3.4 85-2.1 105.5 1.2 20.4 1.5 37.5 1.5 37.5l136.5 4-5.1-209.4Z"
			/>
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M389.999 389v-64.7h-26.6v63.9l26.6.8Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="m309 386.6 54.4 1.6v-63.9H309v62.3Z" />
			<path fill="#fff" stroke="#263238" strokeMiterlimit="10" d="M330.49 354.829h11.4v-30.5h-11.4v30.5Z" />
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M419.1 389.8h15.5l-.3-9-1.4-56.5h-65.3v64l51.5 1.5Z"
			/>
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M419.1 389.8h2.9v-65.5h-54.4v64l51.5 1.5Z" />
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M391.999 341.3h-18.3v12.3h18.3v-12.3ZM389.07 334.185h11.4v-9.8h-11.4v9.8Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M514.6 377.7s.4 11.7.8 14.8c.4 3 3 4.5 6.4 3.8 3.4-.8 76.2 1.1 76.2 1.1s3.4-.8 3.8-4.9c.4-4.2 1.5-10.2 1.5-10.2l-88.7-4.6Z"
			/>
			<path
				fill="#B2B2B2"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M582.3 265.8s5.5 44.6 8 50.1c2.6 5.5 4.5 7.7 7.5 8.8 2.9 1.2 19.6 6.1 21.6 6.3 2 .2 2.9-.8 2.4-3.1-.6-2.4-23.4-54.2-25.3-57.3-2-3.1-8.4-5.7-10.8-6.5-2.4-.8-3.4 1.7-3.4 1.7Z"
			/>
			<path stroke="#263238" strokeMiterlimit="10" d="m593.8 268.3 10.3 57.2" />
			<path
				fill="#B2B2B2"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M618.1 306.5s.6 21 1 22.8c.4 1.8 2 2.6 3.9 2.4 2-.2 8.4-1 9.2-2.9.8-2-.4-16.9-2.7-20.4-2.4-3.5-7.1-4.7-9-4.9-2-.3-2.4 3-2.4 3Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M618.999 329.3c.4 1.8 2 2.6 3.9 2.4 2-.2 8.4-1 9.2-2.9.8-2-.4-16.9-2.7-20.4-1-1.5-2.4-2.5-3.8-3.3l.4 1.1 1.4 19.2h-8.7c.2 2 .3 3.4.3 3.9Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="m495.6 333 131.1 15.2M496.8 337.8l115.1 12.3 9.2-.7M611 358.3s-4.4 17.3-6.3 21.4M497.5 346.3s9.5 5 14.5 23.3M492.7 242.2l83.9 19.3M494 236.9s87.3 19.9 94.6 23.6"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M590.8 340.9c-1-.3-1.9-1.4-1.9-2.4v-13.1c0-1 .8-1.6 1.9-1.3 1 .3 1.9 1.4 1.9 2.4v13.1c0 1-.8 1.6-1.9 1.3ZM576.3 339.4c-1-.3-1.9-1.4-1.9-2.4v-13.1c0-1 .8-1.6 1.9-1.3 1 .3 1.9 1.4 1.9 2.4v13.1c0 1-.9 1.6-1.9 1.3Z"
			/>
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M322.253 324.751h70.3v-51h-70.3v51Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M322.244 324.751h42.4v-51h-42.4v51Z" />
			<path fill="#fff" stroke="#263238" strokeMiterlimit="10" d="M338.944 297.463h8.9v-23.7h-8.9v23.7Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M430.401 223h-48.3v51h49.6l-1.3-51Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M382.091 273.947h42.4v-51h-42.4v51Z" />
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M421.401 251.6h-18.3v12.3h18.3v-12.3ZM398.791 246.659h8.9v-23.7h-8.9v23.7Z"
			/>
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M431.7 273.8h-55.5v51h56.7l-1.2-51Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M376.198 324.751h42.4v-51h-42.4v51Z" />
			<path fill="#fff" stroke="#263238" strokeMiterlimit="10" d="M392.897 297.463h8.9v-23.7h-8.9v23.7Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M312.741 267.07h65.2v-47.3h-65.2v47.3Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M312.774 267.07h39.3v-47.3h-39.3v47.3Z" />
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M328.274 241.779h8.2v-22h-8.2v22ZM273.4 219.2s-.6 1.9.4 4.6c1 2.6 2.5 7.2 3.5 8.6 1 1.4.7 3.5.7 3.5l13.4 6.2s.2-5 .7-5.3c.5-.2 4.2-.2 5-1.6.8-1.3-.1-7.9-.1-7.9s.7-.7.8-1.4c.1-.7-1.8-2.4-1.6-3.7.2-1.3.8-2.8-.1-4.7-1-1.9-3.8-6.6-8.2-8-4.4-1.4-9.2.2-11.1 2.3-1.9 2-3.5 4.4-3.4 7.4Z"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M293.3 220.6c.3-.5 1-2 1-3s-1.4-1.2-3.6-.3c-1.8.7-12.7 4.6-16.9 6.4.9 2.5 2.4 6.8 3.4 8.4.4.4.7.8.9.9 1.1.6 4.1-.6 5.2-2.3.9-1.4 1.3-5.4 1.4-6.7v-.5c.4-1.1 2.1-1.2 3.2-.5 1.2.6 2.3.7 3 .4.8-.3 1-1.1 1-1.6s1.1-.8 1.4-1.2Z"
			/>
			<path fill="#fff" d="M289 224.1s-1.6-2.4-3.1-.4c-1.4 2 1 4.7 1.9 5.2.9.5 1.4-.1 1.6-.8" />
			<path stroke="#263238" strokeMiterlimit="10" d="M289 224.1s-1.6-2.4-3.1-.4c-1.4 2 1 4.7 1.9 5.2.9.5 1.4-.1 1.6-.8" />
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M288 209.5c-4.4-1.4-9.2.2-11.1 2.3-1.9 2-3.5 4.4-3.4 7.4 0 0-.6 1.9.4 4.6.3.7.6 1.6.9 2.6h.1s4.1.9 8.5-1.3c4.5-2.2 11.7-6.2 12.4-7.5.1-.1.2-.2.4-.3-1.2-2.2-4-6.4-8.2-7.8Z"
			/>
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M292.9 219.9s5.4.1 8.2-1.3c2.9-1.4 2.4-4.1 1.9-4.3-.5-.2-8.2.8-8.2.8s.7 3-1.9 4.8Z"
			/>
			<path fill="#263238" d="M295.6 223.1c0 .6-.2 1.1-.4 1.1s-.4-.5-.4-1.1c0-.6.2-1.1.4-1.1.3 0 .4.5.4 1.1Z" />
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M293.6 244.7s-2-3.5-4.4-5.3c-2.3-1.8-8.3-4.1-10.7-4.3-2.3-.2-1.6 1.1-1.9 2.4-.3 1.4-.6 1.8.2 2 .8.2 4.5.9 9.2 3.1 4.7 2.2 7.2 3.6 7.7 3.3.5-.3-.1-1.2-.1-1.2Z"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M285 419.3s3.4 5.5 5.3 6.6c1.9 1.1 5.5 2.3 5.8 4.3.3 2-2.2 1.9-3.4 1.9-1.2 0-9.2.3-10.6-.3-1.4-.5-4.6-3-8.3-5-3.7-2-6.9-2.3-6.9-5.7 0-3.4 3.1-4.1 3.7-4.6.6-.5 14.4 2.8 14.4 2.8Z"
			/>
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M272.3 324.9s3.4 31.9 3.5 33.6c.1 1.6-2.2 7.4-3.1 12.6-.9 5.1-2.2 19.4-2.2 19.4l.4 28s8.1 1.9 9.3 1.8c1.2-.1 4.3-.7 4.7-.8.4-.1 2.7-.9 2.7-.9s-.5-9.3-.5-12.3 1.9-22.1 2.3-24c.4-1.9 5.1-29.8 5.8-44.5.7-14.8-1.8-27.1-1.8-27.1l-23.3 1.2 2.2 13Z"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M278.1 419.9s2 4.9 3 5.7c.9.8 6.9 3.1 7.2 4.2.3 1.1-.8 2-2.3 2.4-1.5.4-12.2.4-14.3-.7-2.2-1.1-8.9-6.1-10.8-6.9-1.9-.8-5-1.9-4.2-5.3.8-3.4 4.9-6 4.9-6l16.5 6.6Z"
			/>
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M268.4 309.3s-1.4 6.6.7 11.8c2.1 5.2 7.7 12 8.7 13.4.9 1.4-.3 17.1-.9 23.1-.7 6.1-1.8 16.4-1.8 16.8 0 .4-5.8 15.3-8.3 22.2-2.4 6.9-6.5 16.8-6.5 16.8s5.4 4.1 8.1 4.9c2.7.8 9.7 1.6 9.7 1.6s.7-3.2 1.4-4.6c.7-1.4-.1-1.9.7-5.5.8-3.7 9.9-23.1 13.3-28.7 3.4-5.5 5.4-15.7 6.8-20.7 1.4-5 1.4-25.4 1.6-32.5.3-7-2-15-2-15l-.7-2.6-30.8-1Z"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M290.7 349.2c-.2 2-.5 4-.8 6.2M289 312.3s-.9 1.5-.6 4c.3 2.5 2.5 3.4 3.7 4.9 1.2 1.5-1.2 2.2-1.8 4-.6 1.8 1.2 3.4 1.2 4.6 0 .9.3 6.3-.2 14.1"
			/>
			<path
				fill="#263238"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M267.4 304.5s-1.1 5.3-.1 5.8c.9.5 30.9.1 31.8 0 .9-.1 1.5-.3 1.6-1.5.1-1.2-.5-4.5-.5-4.5l-32.8.2Z"
			/>
			<path
				fill="#00AEEF"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M276.8 239.5s-9.1 5.5-11.3 12.7c-2.2 7.2.9 39.1.9 39.1s-2.5 11-.2 13.2c2.3 2.2 32.8 1.7 34.1.7 1.4-1 2.4-3.1 2.4-5.3 0-2.2.7-21.6.7-32.4 0-10.8-5.2-17.9-7.5-20.4s-3.9-2.9-3.9-2.9-12.2-4.7-15.2-4.7Z"
			/>
			<path stroke="#263238" strokeMiterlimit="10" d="M271 263c.6 1.2 1.3 2.4 2.3 3.8M270.2 251.7s-1.8 1.8-1 6.2" />
			<path
				fill="#00AEEF"
				d="M291.7 247.4s5.7-1.1 9.5.9 9.9 8.1 11.6 8.9c1.6.8 4.5 3.6 6.9 4.3 2.4.6 6.3 1.9 6.3 1.9s11.5-.8 12.8-.9c1.3-.1 10.9.1 10.9.1s.5 1.7.7 3.7c.2 2 0 3.4 0 3.4s-3.5 2.1-4.4 2.3c-.9.2-9.2 2.4-16.3 4.6-7.2 2.2-18.2.5-25.7-1.5-7.5-1.9-25-9.2-25.9-9.6"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M291.7 247.4s5.7-1.1 9.5.9 9.9 8.1 11.6 8.9c1.6.8 4.5 3.6 6.9 4.3 2.4.6 6.3 1.9 6.3 1.9s11.5-.8 12.8-.9c1.3-.1 10.9.1 10.9.1s.5 1.7.7 3.7c.2 2 0 3.4 0 3.4s-3.5 2.1-4.4 2.3c-.9.2-9.2 2.4-16.3 4.6-7.2 2.2-18.2.5-25.7-1.5-7.5-1.9-25-9.2-25.9-9.6"
			/>
			<path
				stroke="#263238"
				strokeMiterlimit="10"
				d="M314.2 270.4c-1.5.5-3.1.8-4.7 1M325.6 265.2s-3.6 2.3-8.2 4.1M293 270.8s-5.5 10.6-12.3 15.3M285.6 288.1c-1.4 1.6-2.8 3.1-4.2 4.2M293.3 277.8s-2.4 3.8-5.6 7.7"
			/>
			<path
				fill="#fff"
				stroke="#263238"
				strokeMiterlimit="10"
				d="M349.5 262.5s2.1-2.3 3.5-3.6c1.4-1.2 3.1-2 3.9-2.6.8-.6 1.4-3.2 2.6-2.3 1.2.9.9 1.8.5 2.6s-1.4 2.4-1.8 3l-.3.7s3.5.8 5 .8 5.7.2 5.8.9c.2.7-.4 1.5-.7 1.6-.4 0-5.5.4-5.5.4s4.2 2.4 4.2 2.8c0 .4-.9 1.1-2 1.4-1.1.3-2.7 1.7-4.5 1.9-1.7.1-6.8-.2-7.4-.4-.6-.2-2.5-.1-2.5-.1l-.8-7.1Z"
			/>
			<path stroke="#263238" strokeMiterlimit="10" d="m360.6 266 4.1 2.2M359 268.2l2.6 1.1" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M177.585 432.58h90.3v-65.5h-90.3v65.5Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M177.655 432.58h54.4v-65.5h-54.4v65.5Z" />
			<path fill="#fff" stroke="#263238" strokeMiterlimit="10" d="M199.155 397.547h11.4v-30.5h-11.4v30.5Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M164.7 355.8H91.5v65.5h73.2v-65.5Z" />
			<path fill="#00AEEF" stroke="#263238" strokeMiterlimit="10" d="M164.8 355.8h-44.2v65.5h44.2v-65.5Z" />
			<path fill="#fff" stroke="#263238" strokeMiterlimit="10" d="M147.3 355.8h-9.2v30.5h9.2v-30.5Z" />
			<path stroke="#263238" strokeMiterlimit="10" d="M738.5 432.4H0" />
		</svg>
	);
};

export default GoodSvg;
