import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const navigation = [
	{ path: '/', name: 'Home' },
	{ path: '/about', name: 'About' },
	{ path: '/services', name: 'Services' },
	{ path: '/key_professionals', name: 'key Professionals' },
	{ path: '/contact', name: 'Contact us' },
];

const navLinkActive = 'text-blue-500';

const Navbar = () => {
	const [navOpen, setNavOpen] = useState(false);

	return (
		<nav className="fixed top-0 z-10 w-full">
			<div className="container items-center lg:flex">
				<div className="flex items-center justify-between py-3 lg:block">
					<Link to="/" className="relative block h-16 w-28">
						<img
							src="logo.png"
							width={100}
							// height={80}
							alt="Float UI logo"
							className="absolute  rounded-full"
						/>
					</Link>
					<div className="lg:hidden">
						<button
							className="rounded-md p-2 text-gray-700 outline-none focus:border focus:border-gray-400"
							onClick={() => setNavOpen((navOpen) => !navOpen)}
						>
							{navOpen ? (
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
								</svg>
							)}
						</button>
					</div>
				</div>
				<div
					className={`absolute top-0 h-screen w-64 flex-1 transform justify-self-center bg-gray-600 transition-all duration-300 ease-in-out lg:static lg:block lg:h-auto lg:translate-x-0 lg:transform-none lg:bg-transparent lg:duration-0 ${
						navOpen ? 'left-0 ' : '-left-64'
					}`}
				>
					<ul className="items-center justify-end p-6 lg:flex lg:space-x-4 ">
						{navigation.map((item, idx) => {
							return (
								<li key={idx}>
									<NavLink
										to={item.path}
										className={({ isActive }) => (isActive ? navLinkActive : '') + 'inline-block  py-2 lg:p-4'}
									>
										{item.name}
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
