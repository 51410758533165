import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import Female from '../components/svg/female.png';
import Male from '../components/svg/man.png';

const ProfessionalPage = () => {
	const professionalPageData = [
		{
			id: 1,
			name: 'Shahzad Hayder',
			designation: 'CEO',
			img: '',
			gender: 'male',
		},
		{
			id: 2,
			name: 'Atiqa Zahid',
			designation: 'Assistant Manager + Accountant',
			img: '',
			gender: 'female',
		},
		{
			id: 3,
			name: 'Yasir Arafat',
			designation: 'Manager',
			img: '',
			gender: 'male',
		},
		{
			id: 4,
			name: 'Ammad Khan',
			designation: 'Delivery Clerk',
			img: '',
			gender: 'male',
		},
		{
			id: 5,
			name: 'Ibrahim Shahzad',
			designation: 'Port Incharge',
			img: '',
			gender: 'male',
		},
		{
			id: 6,
			name: 'Advocate Usman Manzoor',
			designation: 'Legal Advisor',
			img: '',
			gender: 'male',
		},
	];

	return (
		<div>
			<div className="container py-12">
				<h2 className="py-8 text-center text-[2.5rem] font-bold">Key Professionals</h2>

				<Swiper
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					loop
					slidesPerView={1}
					spaceBetween={50}
					modules={[Autoplay, Navigation]}
					breakpoints={{
						1280: {
							slidesPerView: 3,
						},
						// 1280: {
						// 	slidesPerView: 2,
						// },
						600: {
							slidesPerView: 2,
						},
					}}
				>
					{professionalPageData.map((professional) => (
						<SwiperSlide key={professional.id}>
							<div className="relative flex w-full items-center justify-center overflow-hidden rounded-full bg-gray-300 pb-[50%] pt-[50%]">
								<img src={professional.gender === 'male' ? Male : Female} alt="Gender" className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full' />
							</div>
							<h4 className="mt-4 text-center text-lg font-bold">{professional.name}</h4>
							<p className="mt-3 text-center">{professional.designation}</p>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default ProfessionalPage;
