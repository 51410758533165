import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { RootLayout } from './pages/RootLayout';
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import ProfessionalPage from './pages/ProfessionalPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';

const App = () => {
	return (
		<RouterProvider
			router={createBrowserRouter(
				createRoutesFromElements(
					<Route element={<RootLayout />}>
						<Route path="/" element={<HomePage />} />
						<Route path="about" element={<AboutPage />} />
						<Route path="services" element={<ServicesPage />} />
						<Route path="key_professionals" element={<ProfessionalPage />} />
						<Route path="contact" element={<ContactPage />} />
					</Route>,
				),
			)}
		/>
	);
};

export default App;
