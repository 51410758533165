import HeadingParagraphImageLayout from '../components/HeadingParagraphImageLayout';
import GoodSvg from '../components/svg/GoodSvg';
import VehicleSvg from '../components/svg/VehicleSvg';

const servicesContent = [
	{
		heading: 'Goods Transport',
		paragraph:'We at Bakht Logistics also provide import goods transport services in Karachi to all over Pakistan. In the list of goods transport companies in Karachi, our transportation company stands out, having served thousands of satisfied customers. Our heavy transport operations are coordinated centrally and offer outstanding transportation services for bulky imported items, including heavy transport, industrial services, and project management. This is backed up by our company\'s investment in state-of-the-art hydraulic trailer equipment and in-house engineering facilities to modify trailers according to the cargo\'s nature and shape.',
		svg: <GoodSvg />,
	},
	{
		heading: 'Vehicle Transport',
		paragraph:
			// eslint-disable-next-line quotes
			'Vehicle shifting can be a challenging task, and not all companies understand the technicalities that are involved with moving such a large and expensive item. A lot can go wrong in such a process, and this is what puts fears in the hearts of the clients. However, Bakht Logistics has a skilled team that uses full-proof measures to ensure that your vehicle moving process goes as smoothly as possible.\nOur car carrier truck is kept well-maintained, and our technicians ensure that each truck is equipped with everything needed to keep your car safe during the transportation process. Rapid Movers provides car carrier services in Lahore, Islamabad, Karachi, and all other major cities.',

		svg: <VehicleSvg />,
	},
];

const ServicesPage = () => {
	return (
		<section className="bg-[#E3FEF7] py-8">
			<div className="container">
				<h2 className="py-5 text-center text-[2.5rem] font-bold">Services</h2>
				<div>
					At Bakht Logistics, we provide comprehensive customs clearance services for a diverse range of import categories to facilitate smooth and efficient trade operations. we are specialize in
					<ol style={{ listStyle: 'number' }} className='ml-6 mt-3'>
						<li>
							Earthmoving Machinery and Machinery Parts to
						</li>
						<li>
							Lubricants
						</li>
						<li>
							Japanese Cars
						</li>
						<li>
							Imported Wood
						</li>
						<li>
							Solar Panels
						</li>
						<li>
							Used Clothing
						</li>
						<li>
							Embroidery Machines
						</li>
					</ol>
					<br />
					We handle each consignment with precision and expertise. Our team of customs agents is well-versed in the specific regulations governing each category, ensuring that all necessary documentation is accurately completed and submitted to expedite the customs clearance process. Whether you&rsquo;re importing heavy machinery, environmentally friendly solar solutions, or high-quality textiles, Bakht Logistics is your trusted partner for reliable customs clearance services tailored to your unique import needs
				</div>
				{servicesContent.map((item, index) => (
					<HeadingParagraphImageLayout
						key={index}
						svg={item.svg}
						paragraph={item.paragraph}
						heading={item.heading}
						className={index % 2 === 0 ? '-order-1' : 'lg:order-2'}
					/>
				))}
			</div>
		</section>
	);
};

export default ServicesPage;
