import BannerSvg from './svg/BannerSvg';

const Banner = () => {
	return (
		<div className="bg-[#d1d2d2]">
			<div className="container grid grid-cols-1 items-center lg:grid-cols-2">
				<div>
					<BannerSvg />
				</div>
				<div>
					<div className="flex items-center gap-4">
						<h1 className="text-xl font-bold text-[#0094bb] sm:text-3xl md:text-4xl">BAKHT LOGISTICS</h1>
						<div className="w-28">
							<img src="logo.png" alt="" className="w-full rounded-full" />
						</div>
					</div>

					<p className="pb-8">We’ve got it all COVERED...</p>

					<p>tell us where you want it and we’ll get it there by land, air or sea!</p>
				</div>
			</div>
		</div>
	);
};

export default Banner;
