import { FaFacebook } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { IoLogoWhatsapp, IoMdMail } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { ImLinkedin } from 'react-icons/im';
import { HiMiniBuildingOffice } from 'react-icons/hi2';
const navigation = [
	{ path: '/', name: 'Home' },
	{ path: '/about', name: 'About' },
	{ path: '/services', name: 'Services' },
	{ path: '/key_professionals', name: 'key Professionals' },
	{ path: '/contact', name: 'Contact us' },
];

const contacts = [
	{
		text: '0300-6320292',
		link: 'tel:03006320292',
		icon: <MdPhone className="size-5" />,
	},
	{
		text: '0315-6320292',
		link: 'tel:0315-6320292',
		icon: <IoLogoWhatsapp className="size-5" />,
	},
	{
		text: 'bakhtlogistics@gmailcom',
		link: 'mailto:bakhtlogistics@gmailcom',
		icon: <IoMdMail className="size-5" />,
	},
];

const Footer = () => {
	return (
		<>
			<footer className="bg-[#008080]">
				<div className="container py-8 text-white">
					<div className="flex flex-wrap items-stretch justify-between gap-y-12">
						<div className="basis-full sm:basis-[calc(100%/2)] md:basis-[calc(100%/2.2)] xl:basis-[calc(100%/4)] 2xl:basis-[calc(100%/4.5)]">
							<div className="flex items-center gap-3 pb-4">
								<img src="logo.png" alt="" className="rounded-full" />
							</div>
							<h4 className="mb-3 text-xl font-bold">Bakht Logistics</h4>
							<div className="">
								<ul className="flex gap-4">
									<li>
										<a href="http://" target="_blank" rel="noopener noreferrer">
											<FaFacebook className="size-6" />
										</a>
									</li>
									<li>
										<a href="http://" target="_blank" rel="noopener noreferrer">
											<ImLinkedin className="size-6" />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="basis-full sm:basis-[calc(100%/2)] md:basis-[calc(100%/2.2)] xl:basis-[calc(100%/4)] 2xl:basis-[calc(100%/4.5)]">
							<h4 className="mb-3 text-xl font-bold">Quick Links</h4>
							<ul className="space-y-4">
								{navigation.map((item) => (
									<li key={item.name}>
										<Link to={item.path}>{item.name}</Link>
									</li>
								))}
							</ul>
						</div>
						<div className="basis-full sm:basis-[calc(100%/2)] md:basis-[calc(100%/2.2)] xl:basis-[calc(100%/4)] 2xl:basis-[calc(100%/4.5)]">
							<h4 className="mb-3 text-xl font-bold">Contact Us</h4>
							<ul className="space-y-4">
								{contacts.map((contact) => (
									<li key={contact.text}>
										<a href={contact.link} className="flex gap-3">
											{contact.icon} {contact.text}
										</a>
									</li>
								))}
								<li className="flex gap-3 text-sm">
									<HiMiniBuildingOffice  className="size-6 items-center" />
									C-6, 3rd FLOOR OCEAN CENTER OPP. CUSTOM HOUSE KARACHI
								</li>
							</ul>
						</div>
						<div className="basis-full sm:basis-[calc(100%/2)] md:basis-[calc(100%/2.2)] xl:basis-[calc(100%/4)] 2xl:basis-[calc(100%/4.5)]">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14483.583469920426!2d67.0409056!3d24.8332345!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d843ea434bd%3A0x254e5892d53de109!2sBAKHT%20LOGISTICS!5e0!3m2!1sen!2s!4v1713255294644!5m2!1sen!2s"
								className="h-80 w-full"
								style={{ border: 0 }}
								// allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</div>
					<div className="mt-6 border-t border-white pt-6">
						<p className="text-center text-sm">© {new Date().getFullYear()} Bakht Logistics. All rights reserved.</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
