import Banner from '../components/Banner';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Outlet, useLocation } from 'react-router-dom';

export const RootLayout = () => {
	const location = useLocation();

	return (
		<>
			<Navbar />
			<main>
				{location.pathname !== '/' && <Banner />}
				<Outlet />
			</main>
			<Footer />
		</>
	);
};
