// import BlueBackground from '../components/svg/BlueBackground';

import HomeSvg from '../components/svg/HomeSvg';
import AboutPage from './AboutPage';
import ServicesPage from './ServicesPage';

const HomePage = () => {
	return (
		<>
			<section className="gradient pb-1">
				<div className="h-screen w-full bg-[url('../public/bgIntro.svg')] bg-cover bg-right bg-no-repeat pt-40">
					<div className="container ">
						<div className="flex flex-col justify-between text-white lg:flex-row">
							<div className="lg:basis-1/2 ">
								<div className="mx-auto">
									<h1 className="pb-6 pt-24 text-4xl font-extrabold sm:text-6xl lg:text-5xl xl:text-7xl">BAKHT LOGISTICS</h1>
									<p>tell us where you want it and we’ll get it there by land, air or sea!</p>
								</div>
							</div>
							<div className="lg:basis-1/2">
								<HomeSvg />
							</div>
						</div>
					</div>
				</div>
			</section>
			<AboutPage />
			<ServicesPage />
		</>
	);
};

export default HomePage;
