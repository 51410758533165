import HeadingParagraphImageLayout from '../components/HeadingParagraphImageLayout';
import CertifiedCustomSvg from '../components/svg/CertifiedCustomSvg';
import CustomClearanceSvg from '../components/svg/CustomClearanceSvg';
import ImportCustomSvg from '../components/svg/ImportCustomSvg';

const aboutContent = [
	{
		heading: 'Custom Clearance Services',
		paragraph: `At Bakht Logistics, we take pride in being one of the leading customs clearance agents and freight forwarders serving various cities across Pakistan. Our dedicated Import Freight Forwarding and Goods Forwarding Division ensures the highest quality of service delivered right to your doorstep.
		Specializing in import customs clearance for household items, baggage, and sea shipments, we provide unparalleled expertise in navigating Pakistan's customs regulations. As a trusted customs brokerage firm, we offer exceptional services tailored to our clients' needs, ensuring that your imported goods are safely packed and efficiently cleared from customs at highly competitive rates.
		With over 15 years of industry experience, our team is well-versed in handling all aspects of import documentation and swiftly moving your consignments through the customs process. With offices strategically located throughout Pakistan, we are committed to staying abreast of the latest customs regulations and internal agreement details to secure favorable tariffs and ensure compliance with all management rules.
		Choose Bakht Logistics for seamless import customs clearance services that prioritize efficiency, accuracy, and customer satisfaction.`,
		svg: <CustomClearanceSvg />,
	},
	{
		heading: 'Certified Custom Clearing Agency',
		paragraph: `At Bakht Logistics, we specialize in import customs clearance services, ensuring seamless and efficient processing of imported goods at all Pakistani ports. As a professional and certified customs clearing agency, we handle numerous import projects daily with excellence.
			With our national and international partnerships with leading shipping lines, warehouses, and customs experts, we provide comprehensive import customs clearance solutions tailored to your needs. Whether you're importing household items, baggage, or sea shipments, we guarantee meticulous attention to detail and adherence to all customs regulations.
			Our hassle-free and cost-effective import solutions eliminate the complexities often associated with international trade. From documentation preparation to customs clearance, we handle every aspect of the import process with precision and expertise.
			Choose Bakht Logistics for trusted import customs clearance services that prioritize reliability, efficiency, and customer satisfaction.`,

		svg: <CertifiedCustomSvg />,
	},
	{
		heading: 'Import Custom Clearing Services',
		paragraph: `In today's global marketplace, effective import solutions are crucial for enhancing any trading business. At Bakht Logistics, we understand the importance of reliable import services that not only deliver your goods on time but also provide cost-effective solutions tailored to your business needs.
		With our commitment to excellence, we have become the trusted partner for numerous businesses seeking top-tier import solutions. Our hassle-free approach ensures that we not only offer solutions but the right solutions for your import requirements.
		When goods are importing into Pakistan, specific customs regulations must be adhered to, requiring accurate documentation and submission. Our team of customs agents at Bakht Logistics is highly knowledgeable in Pakistan's import laws and dedicated to providing meticulous care and attention to each customer's import needs.
		Choose Bakht Logistics for unparalleled import custom clearing services that prioritize efficiency, accuracy, and customer satisfaction.`,
		svg: <ImportCustomSvg />,
	},
];
const AboutPage = () => {
	return (
		<section className="bg-[#E3FEF7] py-8">
			<div className="container">
				<h2 className="py-5 text-center text-[2.5rem] font-bold">ABOUT US</h2>
				{aboutContent.map((item, index) => (
					<HeadingParagraphImageLayout
						key={index}
						svg={item.svg}
						paragraph={item.paragraph}
						heading={item.heading}
						className={index % 2 === 0 ? '-order-1' : 'lg:order-2'}
					/>
				))}
			</div>
		</section>
	);
};

export default AboutPage;
